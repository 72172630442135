import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "min-h-screen"
}
const _hoisted_2 = {
  key: 1,
  class: "bg-green-200 min-h-screen text-center"
}
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormPageVue = _resolveComponent("FormPageVue")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.els)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_FormPageVue, {
          branch: _ctx.branch,
          els: _ctx.els,
          "submit-state": _ctx.submitState,
          "update-answer": _ctx.updateAnswer,
          "go-next": _ctx.goNext,
          "go-back": _ctx.goBack,
          "submit-finish": _ctx.submitFinish
        }, null, 8, ["branch", "els", "submit-state", "update-answer", "go-next", "go-back", "submit-finish"])
      ]))
    : (_ctx.fetchState.fetchLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['far', 'snowflake'],
            class: "fa-spin text-9xl text-red-800"
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.fetchState.fetchError), 1))
}